// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gyosei-access-js": () => import("./../../../src/pages/gyosei/access.js" /* webpackChunkName: "component---src-pages-gyosei-access-js" */),
  "component---src-pages-gyosei-charge-js": () => import("./../../../src/pages/gyosei/charge.js" /* webpackChunkName: "component---src-pages-gyosei-charge-js" */),
  "component---src-pages-gyosei-contact-js": () => import("./../../../src/pages/gyosei/contact.js" /* webpackChunkName: "component---src-pages-gyosei-contact-js" */),
  "component---src-pages-gyosei-index-js": () => import("./../../../src/pages/gyosei/index.js" /* webpackChunkName: "component---src-pages-gyosei-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-support-access-js": () => import("./../../../src/pages/support/access.js" /* webpackChunkName: "component---src-pages-support-access-js" */),
  "component---src-pages-support-charge-js": () => import("./../../../src/pages/support/charge.js" /* webpackChunkName: "component---src-pages-support-charge-js" */),
  "component---src-pages-support-contact-js": () => import("./../../../src/pages/support/contact.js" /* webpackChunkName: "component---src-pages-support-contact-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

